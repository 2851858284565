
export default {
  name: "YfnSelect",
  components: {},
  asyncData({ api, cookies, ...context }) {},
  props: {
    item: Object,
    isShow: Boolean,
    index: Number,
  },
  data() {
    return {
      optionReturn(val) {
        let option = this.item.optionList.find((res) => {
          return res.optionValue == val;
        });
        let value = "";
        if (option) {
          this.selectInfo = option;
          let fee =
            option.displayCustomizeFee == "0.00" || option.displayCustomizeFee == "0,00"
              ? ""
              : `(+ ${this.$price(option.displayCustomizeFee)})`;
          value = `${option.optionName || option.optionValue}${fee}`;
        }
        return value;
      },
      selectInfo: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    selectClick(item) {
      this.isShow = !this.isShow;
    },
    handleSelect(c) {
      this.isShow = false;
      if (this.item.optionType == 3) {
        this.item.defaultFont = c;
        this.$emit("change", c);
        return;
      }
      this.item.defaultValue = c.optionValue;
      this.$emit("change", {
        ...c,
        optionType: this.item.optionType,
        serviceType: this.item.serviceType,
        index: this.index,
      });
    },
  },
};
